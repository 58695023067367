@import "../../node_modules/bootstrap/less/variables.less";

div.overlay, div.overlay-sm {
    position: absolute;;
    height: 100%;
    width: 100%;
    background-color:black;
    opacity: .3;
}
.overlay-text, overlay-sm-text, .perm-overlay-text {
    text-shadow: 1px 2px 2px rgba(0,0,0,0.6);
    color: white;
}

@media (max-width: @screen-xs) {
    div.overlay {
        opacity: .25;
    }
    .overlay-text {
        text-shadow: none;
        color: inherit;
    }
}
@media (max-width: @screen-sm) {
    div.overlay-sm {
        opacity: .25;
    }
    .overlay-sm-text {
        text-shadow: none;
        color: inherit;
    }
}

div.hero-caption {
    text-align:center;
    letter-spacing:.2em;
    top: 50%;
    left: 0;
    right: 0;
    position: absolute;
    bottom:10%;
    h1 {
        font-size:64px;
    }
    p {
        font-size:18px;
        padding: 0 8px;
    }
}

@media (max-width: @screen-xs) {
    div.hero-caption {
        top: 22%;
    }
    div.hero-caption h1 {
        font-size: 36px;
    }
    div.hero-caption p {
        font-size:16px;
    }

}

div.caption {
    letter-spacing: @text-space;
    position: absolute;
    left: 50%;
    right:10%;
    top: 15%;
    bottom:10%;
}
div.caption {
    line-height: 1.875em;

}

div.left-caption {
    left: 5%;
    right: 60%;
}

div.left-caption-5-64 {
    left: 5%;
    right: 64%
}


div.left-caption-4-5-56 {
    top: 4%;
    left: 5%;
    right: 56%;
    letter-spacing: .15em;
    text-shadow: none;
}

div.left-caption-4-5-50 {
    top: 4%;
    left: 5%;
    right: 50%;
    letter-spacing: .15em;
    text-shadow: none;
    color:white;
}
div.front-page-box {
    padding: 15px;
}
