.receipt-row {
    margin-top: 20px;
    margin-bottom: 30px;
}
.receipt-row-bottom {
    margin-bottom: 15px;
}
.receipt-brand-label, .receipt-time {
    display: inline-block;
    font-size: 20px
}
.receipt-brand-label {
    letter-spacing: .25em;
}

@media (max-width: 510px) {
    .receipt-logo {
        display: none;
    }
}

.receipt-logo {
    height: 40px;
    margin-right:10px;
    position: relative;
    top: -6px;
}


@media (max-width: @screen-sm) {
    .receipt-logo {
        margin-right:20px;
    }
}
@media (min-width: 511px) {
    div.receipt-time {
        height: 40px;
    }
    span.receipt-time {
        line-height: 34px;
    }
}
@media (max-width: 380px) {
    .receipt-brand-label, .receipt-time {
        font-size: 16px
    }

}

.panel-title > a.link-blue {
    color: #337ab7;
}
