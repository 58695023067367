@import "../../node_modules/bootstrap/less/variables.less";
@import "../core/variables.less";
@import "../core/captions.less";


body {
    padding-top: 50px;
}

.navbar {
    background-color:@nav-bg-color;
    border-bottom-color: #ddd;
    letter-spacing: .1em;
    color:white;
    .navbar-brand {
        font-size:20px;
        /*font-weight: bold;*/
        color:white;
        position: relative;
        display: inline-block;
        max-width: calc(100% - 150px);
        white-space: nowrap;

        .navbar-logo {
            position: absolute;
            top: 8px;
            height: 35px;
            /*width: 32px;*/
        }
    }
    .navbar-brand:focus {
        color:white;
    }
    @media (min-width: @screen-md) {
        .navbar-brand {
            .navbar-brand-label {
                padding-left: 28px;
            }
            .navbar-logo {
                padding-left: 10px;

            }
        }

    }
    @media (max-width: @screen-xs) {
      .navbar-brand {
          padding: 17px 10px 13px 10px;
          font-size:18px;

          /*
          .navbar-logo {
              top: 8px;
              height: 32px;
          }*/
          .navbar-brand-label {
              display: none;
              margin-left: 58px;
          }
      }
    }
    @media (max-width: @screen-xxs) {
      .navbar-brand {
          font-size:18px;
          max-width: 224px;

          .navbar-brand-label {
              display: none;
              /*
              letter-spacing: .1em;
              margin-left: 50px;
              */
          }
      }
      .shopping-cart {
          padding-left: 0px;
      }
    }
    .navbar-brand:hover {
        color: lightgray;
    }
    .navbar-brand-label {
        margin-left: 76px;
        letter-spacing: .25em;
        white-space: nowrap;
    }
    ul.navbar-nav li > a, ul.navbar-nav li > a:visited {
        letter-spacing: .1em;
        color:white;
    }
    ul.navbar-nav.home li > a, ul.navbar-nav li > a:visited {
        font-size:18px;
    }

    ul.navbar-nav li a:hover {
        color:lightgray;
    }

    &.navbar-white {
        .navbar-nav li a:hover {
            color: @link-blue;
        }
        .navbar-nav.nav .open >a {
            background-color: #37373B;
            color: white;
        }
    }

    .navbar-nav>.active>a, .navbar-nav>.active>a:hover, .navbar-nav>.active>a:focus {
        background-color: #37373B;
        color:white;
    }
    li.dropdown > ul > li > a, li.dropdown > ul > li > a:hover {
        color: black;
    }


}

.navbar {
    &.navbar-inverse {
        .navbar-brand {
            color: #eee;
        }
    }
}
/*
.nav > li > a {
    padding: 15px 8px;
}
*/

.nav-orange {
    background-color: @orange;
    color:white;
}
.navbar-default .navbar-nav>li>a.nav-orange:hover {
    background-color: @orange;
    color: lightgray;
}

.pc-admin-nav {
    border: 0;

    .nav > li > a {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.table-results {
    thead tr th {
        background-color: #ddd;
    }
    th.stretch,
    td.stretch {
        width: 100%;
    }
    th.nowrap,
    td.nowrap {
        white-space: nowrap;
    }
}


.panel-danger-zone {
    border: 1px solid #d9534f;

    p {
        margin-bottom: 0;
    }

    .panel-heading {
        color: #eee;
        background-color: #d9534f;
    }
}


.footer {
    /*margin-top: 50px;*/
    margin-top: 0px;
    letter-spacing: .1em;
    color: white;
    border-top: none;
    border-bottom-color: @nav-bg-color;

    .container {
        padding: 20px 15px;
    }
    a, a:visited {
        color: white;
        text-decoration: none;
    }
    a:hover {
        color:lightgray;
    }
    .links {
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            padding-right: 10px;
            margin-right: 10px;
            list-style: none;
            /*border-right: 1px dotted #999;*/
            border-right: 1px solid white;
        }
        li:last-child {
            padding-right: 0;
            margin-right: 0;
            border: none;
        }
    }
}

@media (max-width: @screen-xs) {
    .footer {
        font-size:12px;
    }
}

.spinnerContainer {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    height: 16px;
    width: 16px;
}


/* prevent ios zoom on input */
select, textarea, input, .form-control {
  font-size: 16px;
}

select {
    -webkit-appearance:none;
}

div.input-group.answer-group {
    margin-bottom: 1em;
}

.input-group input.tbInput.form-control:first-child,
div.input-group input.tbInput.form-control:not(:first-child):not(:last-child)
{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
button.tbBtnLast {
    border:none;
}
button.tbBtn {
    border:none;
}
button.tbBtn:disabled {
    opacity: 1;
}

body.noscroll {
    overflow: hidden;
    position: fixed;
}

div.form-group > input[type=checkbox] {
    max-width: 2em;
    /*margin-left:-5px;*/
}

.middle-btn-form-control-workaround {
    border: 0;
    height: 32px;
}

html {
    position: relative;
    min-height: 100%;
}
body {
    /* Margin bottom by footer height */
    margin-bottom: 80px;
    @media (max-width: @screen-xs) {
        margin-bottom: 50px;
    }
}
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 60px;
    background-color:@nav-bg-color;
    color:white;
    @media (max-width: @screen-xs) {
        height: 30px;
    }
}
@media (max-width: @screen-xs) {
    .footer {
        border-top: 1px solid #ccc;
    }
    .footer .container {
        padding: 5px 15px
    }
}
.row-no-padding {
    [class*="col-"] {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.orange {
    color: @orange;
}
.red {
    color: #ef485e;
}
.blue {
    color: #6ea6d9;
}
.link-blue {
    color: #337ab7;
}
.green {
    color: #43bea2;
}
.home-text {
    color: @home-text-color;
}


body {
    background-color:#f8f9fa;
    color: #373739;
}
/*
.row.equal {
    flex-wrap: wrap;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
}
.row.equal > [class*='col-'] {
    display: flex;
    flex-direction: column;
}*/

@media only screen and (min-width : 481px) {
    .row.equal {
        display: flex;
        flex-wrap: wrap;
    }
    .row.equal > [class*='col-'] {
        display: flex;
        flex-direction: column;
    }
    .row.equal:after,
    .row.equal:before {
        display: flex;
    }
}




div.hero-panel {
    letter-spacing: @text-space;
    a, a:visited {
        color:  #337ab7;
        text-decoration: none;
    }
    h3 {
        letter-spacing: 0.1em;
    }
    > div {
      position: relative;
      padding:0 2em 2.5em 2em;
        p.hero-panel-footer {
            position:absolute;
            bottom: 0;
        }
    }
    .hero-button, .hero-button:visited {
        color: white;
        text-align: center;
    }
}

@media (max-width: @screen-xs) {
    div.hero-panel {
        > div {
            padding:0 1em;
            > p.hero-panel-footer {
                position: relative;
                bottom: 0;
            }
        }
    }
}

.stretch {
    background-attachment: scroll;/*if not scroll it will stay put*/
    background-clip: border-box;
    background-origin: padding-box;/*origin 0,0 is top left of padding box*/
    background-position-x:50%;/*when using percents it means move x% place in image to x% place in container.  yea that's not obvious*/
    background-position-y:50%;
    background-size: cover; /*scale as large as need without stretching to cover, cut parts outside */
    border-image-repeat: stretch;/*not sure if I need this */
    border-image-slice:100%;/*not sure if I need this */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position:relative;

}
.hero-gap {
    margin-bottom: 20px;/* want 40 between image and text*/
}
.image-gap {
    margin-bottom: 20px;/* padding-bottom like p's have */
    margin-top: 20px;
}
div.callout-hero {
    position: relative;
    .stretch {
        height: 80vh;
        max-height: 600px;
    }

    @media ( max-width: calc(@screen-sm - 1px) ) {
        .darkCallout {
            margin-top: 20px;
        }
        .stretch {
            height: 40vh;
        }
    }
    @media ( max-width: @screen-xs ) {
        .stretch {
            height: 30vh;
        }
    }
    .darkCallout {
        i {
            font-size: 2em;
            float:left;
            margin-right: 12px;
        }
        h3 {
            margin-top: 0;
        }
    }
    @media (min-width: @screen-sm) {
        div.callout-hero-caption {
            position: absolute;
            width: 100%;
            left:0;
            bottom: 0;
            padding: 7% 3% 1% 3%;
            z-index:2;
            color:white;

            h1, p {
                color:white;
                text-align: left;
            }

        }
        .darkCallout {
            padding: 28px;
            h3, p, i {
                color: rgba(255, 255, 255, 1);
            }
            -webkit-border-radius: 12px;
            -moz-border-radius: 12px;
            border-radius: 12px;
            background-color:rgba(0, 0, 0, 0.3);


        }
        .heroRight {
            left: 50%;
        }
        .heroLeft {
            left: 0;
        }

    }
    @media (min-width: @screen-lg) {
        div.callout-hero-caption-smash-left {
            width: 50%;
        }
    }
}


a.hero-button {
    padding: 6px;
    display: inline-block;
    border: solid 1px white;
    color: white;
    width: 180px;
    margin: 20px 20px;
    text-decoration: none;
    font-size: 24px;
}
a.hero-button:visited {
    color: white;
}

button.hero-button {
    border: none;
    color: white;
    text-decoration: none;
    background: none;
    width: 100%;
    text-align: left;
}



ul.pc-list {
    margin: 0;
    padding: 0;
    list-style: none;

}

/*
.row.equal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
    flex-wrap: wrap;/
}
.row.equal > [class*='col-'] {
    display: flex;
    flex-direction: column;
}*/

.row-panel-equal {
    height: calc(~"100% - 20px");
}
.product-panel {
    padding-bottom: 10px;
    position: relative;
    background-color: #efefef;
    border-color: #d5d5d5;
    .panel-body {
        padding: 0 0 10px 0;
        > div, > p {
            padding: 0 15px;
        }
    }
    > h4 {
        margin-top: 20px;
    }
}.product-compact-panel {
    position: relative;
    background-color: #efefef;
    border-color: #d5d5d5;
    .panel-body {
        padding: 0;
        > div, > p {
            padding: 0 15px;
        }
    }
}

.product-title-div {
    margin-bottom: 20px;
    a {
        text-decoration: none;
    }
}
@media (min-width: @screen-sm) {
  .product-title-div {
      min-height: 5.8em;
  }
}
@media (min-width: @screen-md) {
    .product-title-div {
        min-height: 7.5em;
    }
}
@media (min-width: @screen-lg) {
    .product-title-div {
        min-height: 5.5em;
    }
}
.product-panel-footer {
    width: 100%;
    position: absolute;;
    bottom: 10px;
    padding: 0 15px;
    left: 0;

    button {
        margin-top: 24px;
    }
}
.product-c-m-l {
    border-left: solid 1px #999;
    margin-left: 2px;
    padding-left: 12px;

    h5 {
        margin-bottom: 18px
    }

}
.product-block {
    background-color: #efefef;
}
.product-block ul, .product-block ol {
    margin: 0;
    padding-left: 0;
    > li {
        list-style-position: inside;
        padding-bottom: 4px;
    }

}

.modal-preview {
    .modal-content {
        background-color: #efefef;
        .modal-title {
            color: rgb(55,55,55);
        }
    }
}

.warning-modal {
    .modal-header {
        color: #fff !important;
        background: @required-color !important;
    }
    .dismiss-btn {
        margin-top: 10px;
        border-radius: 20px;
        padding: 6px 35px;
        margin-right: 10px;
    }
    .confirm-btn, .btn-primary {
        background-color: @required-color !important;
        border-color: @required-color !important;
        color: white !important;
        margin-right: 10px;
    }

    .custom-card & .btn-primary {
        background-color: @required-color !important;
        border-color: @required-color !important;
    }
}

.confirmation-modal {
    .btn-default {
        color: #5e5e5e;
        margin-top: 10px;
        border-radius: 20px;
        font-weight: bold;
        padding: 6px 35px;
    }
    .modal-header, .confirm-btn {
        color: #fff;
        background: @required-color;
    }
}

.no-padding-modal {
    .modal-body {
        padding: 0px;
    }
}

.default-modal {
    .modal-header {
        background-color: @header-color;
        position: sticky;
        top: 0;
        z-index: 1001;

        .close {
            opacity: 0.5;
        }
        .modal-title {
            color: #fff;
            font-weight: bold;
        }        
    }

    .dismiss-btn, .btn-round {
        border-radius: 20px;
        min-width: 169px;
        text-transform: uppercase;
    }
    .confirm-btn, .btn-primary {
        border-radius: 20px;
        background-color: @primary-color;
        border-color: @primary-color;
        margin-left: 5px;
        min-width: 169px;
        text-transform: uppercase;
    }
    .btn-info {
        background-color: #007dba;
        color: #fff;
        border: 0;
        text-transform: capitalize;

        &:hover, &:focus, &:active {
            background-color: #007dba;
            color: #fff;
        }
    }
}

div.modal.modal-video-dialog > div.modal-dialog {
    max-width: calc(178vh - 120px);
    width: 96%;
}
.circle-close {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    top: -20px;
    right: 0px;
    position: absolute;
    width: 50px;
    background: #efefef;
    border:none;
    padding-top: 4px;
    i.fa {
        font-size: 20px;
    }
}
.modal-background-opacity-06 {
    opacity: .6 !important;
}
@media ( min-width: @screen-sm ) {
    .circle-close {
        width: 40px;
    }
}

@media ( max-width: @screen-sm ) {
    .modal-dialog-circle-close {
        top: 20px;
    }
}

button.list-page-product-preview {
    font-size:18px;
    border: 0;
    padding: 0;
    margin: 0;
    position: relative;
    text-decoration: none;
}
button.list-page-product-preview:hover {
    text-decoration: none;
}
button.list-page-product-preview > p {
    margin: 20px 0 16px 0;
    font-size:18px;
}
button.list-page-product-preview > p > i {
    margin-left: 4px;

}

h4.list-page-product {
    margin: 20px 0 10px 0;
}
.list-page-product-overview {
    overflow: hidden;
    position: relative;
    p {
        padding: 0;
        margin: 0;
    }
    margin-bottom: 180px;
}
/*
.list-page-product-overview:before {
    content:'';
    width:100%;
    height:100%;
    position:absolute;
    left:0;
    top:0;
    background:linear-gradient(transparent 400px, rgba(239,239,239,2));
}*/

.list-page-product-image {
    max-width: 100%;
    margin: 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #d5d5d5;
}

.purchase-btn {
    background-color: @orange;
    color: white;
    margin-top: 15px;
    margin-bottom: 15px;
    line-height: 2.3;
    padding: 2px 12px;
    letter-spacing: 1px;
    span {
        /*
        margin-left: -23px;
        */
    }
    .fa {
        font-size:24px;
        vertical-align: sub;
        padding-right: .4em;
    }


}

@media ( min-width: @screen-sm ) {
    .purchase-btn-compact {
        width: 140px;
        float: right;
    }
    .purchase-btn-to-cart {
        display: none;
    }
}
.purchase-btn.purchase-btn-compact {
    span {
        margin-left: 0;
    }
}
.purchase-btn:hover, .purchase-btn:focus {
    background-color: @orange;
    color: #efefef;

}

div.online-training-hero {
    margin: 20px 0px;
}

.soon {
    text-align: center;
    margin: 3px;
    > i {
        margin-right:.3em;
    }
}

.dollars {
    /*
    font-size: 1.4em;
    */

}

.auto-complete {
    position: relative;
    .list-group {
        width: 100%;
        z-index:1;
        top: 5px;
        position: absolute;
    }
    .list-group-item {
        border-top: none;
        a {
            cursor: pointer;
        }
    }
}

.cents {
    /*
    font-size: 1em;
    padding-left:.2em;
    vertical-align: top;
    */
}

.pc-admin-footer {
    background-color:#37373B;
}

.modal-logo {
    height: 30px;
    padding-right:10px;
    vertical-align: text-bottom;

}

div.inline-checkbox {
    margin: 20px 0;
}
label.inline-checkbox {
    margin-left: 1px;
}
input.inline-checkbox {
    margin-right : 10px;


}

button.edit-button {
    width: 50px;
    > i {
        font-size: 32px;
    }
    background: transparent;
    > i.fa-edit {
        transform: translateY(1px);
    }
    > i.fa-eye {
        transform: translateY(-2px);
    }
}

button.edit-button.edit-button-plus {
    width: 60px;
    i.fa-plus {
        font-size:16px;
        transform: translate(-2px, -17px);
    }
}

div.edit-bar, button.edit-bar {
    // margin-top: -7px;
}

button.no-focus {
    padding: 0;
    border:0;
}
i.no-focus {
    outline: none;
    /*
    padding: 2px 8px 3px 8px;
    */
}

button[disabled], button.disabled > i {
    opacity: .55;
    filter: alpha(opacity=55);
}
div.modal.modal-wide > div.modal-dialog {
    width: 96%;
    max-width:2000px;
    @media(max-width: @screen-xs) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}

img.sweater-guy {
    min-height: 50vw;
}

@media (min-width: calc(@screen-sm)) and (max-width: @screen-md) {
    div.hero-panel.sweater-guy > div.demo {
        padding: 1.5em .5em 0em .5em;
    }
}
/*
@media (max-width: calc(@screen-sm - 1px)) {
    div.callout-hero > div.stretch.sweater-guy {
        height: 40vh;
    }
}*/

i.ws-icon {
    font-size:28px;
    padding-right:12px;
}
h3.ws-icon {
    display: inline-block;
}
div.schedule-buy-panel > div:first-child {
    padding: 0 3em 0 2em;
}
div.schedule-buy-panel > div:last-child {
    padding: 0 2em 0 1em;
}
@media ( max-width: 480px ) {
    div.schedule-buy-panel > div:first-child, div.schedule-buy-panel > div:last-child {
        padding: 0 1em;
    }
}
@media (min-width: @screen-xs) and (max-width: @screen-sm) {
    div.schedule-buy-panel {
        margin-top: -20px;
    }
}

div.callout-hero-caption.callout-hero-caption-smash-left.headset-guy {
    bottom: 10%
}

@media (max-width: 480px) {
    div.stretch.headset-guy {
        height: 25vh;
    }
}

div.cc_form {
    position: relative;
}
div.cc_mask {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: .1;
    z-index: 1;
}

button.filter-tag {
    color:#337ab7;
    font-weight: bold;
    display: inline-block;
    border: 1px solid #337ab7;
    margin: 4px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

button.catalog-filter {
    letter-spacing: .05em;
    background-color: #f68b29;
    border-color: #f68b29;
    color:white;
    font-weight: bold;
    font-size:19px;
    padding: 3px 12px 2px 12px;
}

table.filter-table {
    width: 100%;
    tbody > tr > td > {
        padding-top:15px;
        border-bottom: 1px solid lightgray;
    }
}
div.filter-buttons {
    padding-top: 10px;
    padding-bottom: 10px;
    > button {
        min-width: 140px;
    }
}

@media (max-width: @screen-xs) {
    div.modal.no-margin {
        > div.modal-dialog {
            margin: 0;
            > div.modal-content {
                border: none;
                border-radius: 0;
            }
        }
    }
}

.no-margin {
    margin-bottom: 0 !important;
}
.no-padding {
    padding: 0 !important;
}
.steps {
    vertical-align: top;
    padding: 0 6px;
    font-size: 1.4em;
}
.steps:first-child {
    padding-left: 0;
}
.steps:last-child {
    padding-right: 0;
}

button.btn-height-stretch {
    height: auto;
    white-space: normal;
}

div.tos {
    /*font-family: Times New Roman, serif;*/
    h3.title, h5.subtitle {
        margin-bottom:20px;
    }
    margin-bottom: 20px;
    max-height: 70vh;
    overflow-y: auto;
}
button.tos {
    min-width: 120px;
}

h4.order-complete {
    margin: 20px 0;
}

.receipt-row {
    margin-top: 20px;
    margin-bottom: 30px;
}
.receipt-row-bottom {
    margin-bottom: 15px;
}
.receipt-brand-label, .receipt-time {
    display: inline-block;
    font-size: 20px
}
.receipt-brand-label {
    letter-spacing: .25em;
}

@media (max-width: 510px) {
    .receipt-logo {
        display: none;
    }
}

.receipt-logo {
    height: 40px;
    margin-right:10px;
    position: relative;
    top: -6px;
}


@media (max-width: @screen-sm) {
    .receipt-logo {
        margin-right:20px;
    }
}
@media (min-width: 511px) {
    div.receipt-time {
        height: 40px;
    }
    span.receipt-time {
        line-height: 34px;
    }
}
@media (max-width: 380px) {
    .receipt-brand-label, .receipt-time {
        font-size: 16px
    }

}

.braintree-hosted-fields-focused {
    border-color: rgba(82,168,236,.8);
    outline: 0;
    outline: thin dotted \9;
    -moz-box-shadow: 0 0 8px rgba(82,168,236,.6);
    box-shadow: 0 0 8px rgba(82,168,236,.6);
}

.braintree-hosted-fields-focused.braintree-hosted-fields-invalid {
    border: 1px solid #ebcccc;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(100,100,0,.6);
}
/*this leads to green showing for things that are "potentially valid"
.braintree-hosted-fields-valid {
    border: 1px solid rgba(76, 174, 76, .6);
}
.braintree-hosted-fields-focused.braintree-hosted-fields-valid {
    border: 1px solid green;
    -moz-box-shadow: 0 0 8px rgba(76, 174, 76, .6);
    box-shadow: 0 0 8px rgba(76, 174, 76, .6);

}
*/

.cart-space {
    letter-spacing: .05em;
    max-width: 1230px;
    margin: 0 auto;
}
.helper-text {
    color: #8A6D3B;
    font-size: 12px;
    margin-top: 5px;
    height: 12px;
    display: block;
}

.categories > span {
    padding: 0 4px;
}

a.hero-button:active {
    color: lightgray;
    border-color:lightgray;
}

.unit-list {
    > div {
       min-height: 42px;
    }
    /*
    background-color: #ddd;
    padding: 10px 0;

    border: 1px solid #ddd;
    border-radius: 8px;
    */
}

.unit-list-count-span {
    vertical-align: top;
    min-width: 22px;
    display: inline-block;
}
.unit-list-name {
    width: calc(100% - 22px);
    display: inline-block;
}
.unit-list-feature-col {
    padding: 0;
}

.panel-margined {
    margin: 10px 15px 20px 15px;
}

button.icon-button, div.icon-button, span.icon-button {
    background-color: inherit;
    padding: 2px 6px;
    margin: 0;
    border: 0;
    display: inline-block;
}

@media (max-width: 375px) {
    .panel-margined {
        margin: 0 0 15px 0;
        border: none;
        border-radius: 0;
        box-shadow: none;
    }
    .unit-list-feature-col {
        padding: 0 15px 0 0;
    }
    button.icon-button, div.icon-button {
        padding: 2px 4px;

    }
    .unit-list-count-span {
        display:none;
    }
    .unit-list-name {
        width: 100%;
    }

}

p.unit-list-bottom-space {
     margin: 0 0 22px 0;
}

div.unit-list-left {
    padding-left: 15px;
    display: inline-block;
    width: calc(100% - 133px);
}
div.unit-list-right {
    padding-right: 15px;
    display: inline-block;
    width: 133px;
    vertical-align: top;
}

.product_short_description {
    display: inline-block;
    max-width: 700px;
}

.show-more-less {
    display: inline;
    padding: 0;
    margin: 0;
    border: 0;
    text-decoration: none;
    @media (min-width: @screen-sm) {
        display:none;
    }
}

.video-bg {
    overflow: hidden;
    position: relative;
}

video#bgvid {
    transform: translateX( calc( (-50% + 50vw)));
    /*
    @media (min-width: @screen-lg) {
        width: 100%;
        transform: translateX(0) translateY(0);
    }
    @media (max-width: @screen-md) {
        transform: translateX( -20%) translateY(0);
        width: 100%;
    }*/
    width: 100%;
    @media (max-width: @screen-sm) {
        transform: translateX( -10%) translateY(0%);
        width: 120%;
    }
}

div.progress-row > div {
    padding-left: 0;
    padding-right: 0;
}

button.play-pause {
    border: none;
    color: white;
    text-decoration: none;
    background: none;
    text-align: left;
    font-size:22px;
    min-width:40px;
    text-align: center;
    padding: 1px 7px 2px 7px;
}

.bg-play-pause {
    position: absolute;;
    right: 12px;
    top: 12px;
    background-color: rgba(0,0,0,0.3);
    z-index: 3;
    padding: 6px;
    border-radius: 6px;
    color: white;
    > i {
        font-size: 22px;
        opacity: 1;
        padding-left: 8px;
        padding-right: 8px;
    }
}

.demo-reset {
    min-width: 100px;
}

.font400 {
    font-weight: 400;
}
.font500 {
    font-weight: 500;
}
.font600 {
    font-weight: 500;
}

.bullets  ul {
    margin: 0 0 0 -3px;
    padding-left: 0;
    list-style-position: outside;
    display: table;
    > li {
        list-style-type: none;
        display: table-row;
    }
    > li:before {
        color: @blue;
        font: normal normal normal 18px/1 FontAwesome;
        content: '\f00c';
        margin-left: -0px;
        margin-right: 10px;
        display: table-cell;
        padding: 5px 10px 5px 5px;
    }
}

.responsive-preview-div {
    max-width: 400px;
    max-height: 225px;
    margin: 0px 0 30px 20px;
    float:right;
    width: 40vw;
    height: 22.5vw;
    position: relative;
    > button {
        padding: 0;
        border: 0;
        background: transparent;
        > img {
            width: 100%;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            border: solid 1px lightgray;
        }
        > i {
            position: absolute;
            top: calc(50% - .4em);
            left: calc(50% - .3em);
            color: white;
            font-size: 10vw;
            opacity: .8;
            @media (max-width: @screen-sm) {
                font-size: 48px;
            }
            @media (min-width: @screen-lg) {
                font-size: 84px;
            }
        }
    }
    > img {
        width: 100%;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        border: solid 1px lightgray;
    }
}

@sale-green : #568c0f;

.sale-text {
    /*
    color: #f9112f;
    */
    color: @sale-green;
}
.old-sale-text {
    text-decoration: line-through;
}

.sale-banner {
    position: relative;
    top: -2px;
    font-weight: 600;
    color: white;
    background-color: @sale-green;
    display: inline-block;
    padding: 4px 20px 4px 20px;
    margin-left: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-size: 18px;
    @media(max-width: @screen-xs) {
        padding: 4px 12px 4px 12px
    }
}
sale-price {
    color: @sale-green;
}
.hero-sub-caption {
    text-align:left;
}
.hero-caption, .hero-sub-caption {
    line-height: 1.4;
}

.clearnav.navbar {
    background-color: transparent;
    @media(max-width: 991px) {

        #navbar {
            background-color: #58585a;
        }
    }
}
.video-bg.clearnav {
    position: relative;
    z-index: 11;
}

.clearnav-back {
    /*max-width: 1200px;*/
    width: 100%;
    height: 51px;
    background-color: #58585a;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 10;
    top: 0;
}

body.clearnav {
    padding-top: 0;
}

.width100 {
    width: 100%;
}


/* New layout for admin and might eventually be used for client side as well */
.new-layout {
    .radio-wizard {
        margin: 5px 20px 5px 0;
        display: block;
    }

    &.disabled {
        opacity: .7;
    }

    // Floating label start
    .form-control {
        position: relative;
        margin-bottom: 0;

        &.form-control-field {
            background: none;
            box-shadow: none;
            padding: 14px 10px;
            height: initial;
            line-height: initial;
        }

        &[disabled]:not(button) {
            color: #aaa;
        }
    }

    .form-group {
        position: relative;
        margin-bottom: 2rem;

        .form-control-field.multi-box-other-text {
            margin-top: 1rem;
        }

        .form-control-placeholder {
            position: absolute;
            top: 10px;
            left: 8px;
            padding: 7px 0 0 0;
            transition: all 200ms;
            background-color: #fff;
            padding: 4px;
            color: #aaa;
            pointer-events: none;
        }

        .react-datepicker-wrapper {
            display: block;
        }

        // input[type=checkbox] ~ .form-control-placeholder {
        //     display: none;
        // }

        .form-control-field:focus ~ .form-control-placeholder,
        .form-control-field[required]:valid ~ .form-control-placeholder,
        .form-control-field[value]:not([value=""]) ~ .form-control-placeholder,
        .form-control-field.has-value ~ .form-control-placeholder,
        .react-datepicker-wrapper ~ .form-control-placeholder,
        .DraftEditor-root ~ .form-control-placeholder
        {
            font-size: 80%;
            transform: translate(0px, -22px);
        }
    }
}
/*
.new-layout {
    background-color: #ddd;

    & .after-breadcrumb {
        background-color: #ddd;
    }

    .container-fluid.main {
        margin: 10px 20px;

        @media (max-width: @screen-md) {
            margin: 10px 0;
        }
    }
}

// Custom Card
.custom-card, .new-layout {
    // box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
    border-radius: 0.5rem;
    border-width: 1px;
    background-color: #fff;
    margin-bottom: 40px;

    &.disabled {
        opacity: .7;
    }

    // Floating label start
    .form-control {
        position: relative;
        margin-bottom: 0;

        &.form-control-field {
            background: none;
            box-shadow: none;
            padding: 14px 10px;
            height: initial;
            line-height: initial;
        }
    }

    .form-group {
        position: relative;
        margin-bottom: 2rem;

        .form-control-field.multi-box-other-text {
            margin-top: 1rem;
        }

        .form-control-placeholder {
            position: absolute;
            top: 10px;
            left: 8px;
            padding: 7px 0 0 0;
            transition: all 200ms;
            background-color: #fff;
            padding: 4px;
            color: #aaa;
        }

        .react-datepicker-wrapper {
            display: block;
        }

        // input[type=checkbox] ~ .form-control-placeholder {
        //     display: none;
        // }

        .form-control-field:focus ~ .form-control-placeholder,
        .form-control-field[required]:valid ~ .form-control-placeholder,
        .form-control-field[value]:not([value=""]) ~ .form-control-placeholder,
        .form-control-field.has-value ~ .form-control-placeholder,
        .react-datepicker-wrapper ~ .form-control-placeholder
        {
            font-size: 80%;
            transform: translate(0px, -22px);
        }
    }
    // Float label end

    .table-striped>tbody>tr:nth-of-type(odd) {
        background-color: #e8faf6;
    }

    .btn {
        border-radius: 6px;

        .caret {
            color: @primary-color;
        }

        &.dropdown-toggle {
            border-radius: 4px;
        }
    }

    .btn-primary {
        background-color: #f68d2e;
        color: #fff;
        border-color: #f68d2e;
        border-radius: 20px;
    }

    .btn-rounded {
        border-radius: 20px;
    }
}

.custom-card-header {
    display: flex;
    padding: 12px 20px;
    border-bottom: 1px solid rgba(7, 9, 25, 0.125);

    background-color: #6cace4;
    border-radius: 0.5rem 0.5rem 0 0;
    border-bottom: 0;
    color: #fff;

    .btn-link {
        width: 40px;
        height: 40px;
        border: 2px solid #fff;
        border-radius: 50%;
        position: relative;
        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
        background-color: #fff;
        color: #6cace4;
        vertical-align: middle;

        &.disabled {
            box-shadow: initial;
            opacity: 0.7;
        }
    }

    & .fa {
        font-size: 24px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    & .custom-card-header-title {
        color: #fff;
        text-transform: uppercase;
    }
}

.custom-card-header-title {
    flex-grow: 1;
    color: #F68B29;

    & h3 {
        margin: 0;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}

.custom-card-header-actions {
    text-align: right;
}

.custom-card-body {
    padding: 20px;
    clear: both;

    .client-details-icons {
        .fa-trash {
            color: #a94442;
            font-size: 26px;
            margin: 12px 2px 0 0;
        }

        .fa-plus {
            color: #fff;
            background-color: @primary-color;
            padding: 4px 5.15px 2px;
            border-radius: 24px;
        }

        .fa-exchange {
            color: @primary-color;
        }
    }
}

.elevation1 {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.elevation2 {
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.elevation3 {
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
}

.custom-card-sub-section {
    // margin-bottom: 32px;
    &:last-child {
        margin-bottom: 0;
    }
}

.custom-card-sub-section-header {
    padding: 10px 20px;
    display: flex;
    background-color: #bce6db;
    color: #5e5e5e;
    text-transform: uppercase;
    // border-top: 2px solid rgba(246, 139, 41, .4);
}

.custom-card-sub-section-header-title {
    flex-grow: 1;

}

.custom-card-sub-section-header-actions {
    text-align: right;
}

.custom-card-sub-section-body {
    // .btn-primary {
    //     background-color: #337ab7;
    //     border-color: #2e6da4;
    // }

    .help-block {
        margin: 0;
    }

    .flex-col-custom {
        padding: 0;

        .custom-content {
            padding: 20px;
        }

        h5 {
            margin-top: 0;
        }

        &.flex-col-padding {
            padding: 20px;
        }
    }

    &.sortable .custom-content {
        background-color: #fff;
    }
}

.flex-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.flex-col {
    flex-grow: 1;
    width: 50%;
    padding: 20px;
    border-right: 1px solid #bce6db;
    border-bottom: 1px solid #bce6db;

    &:nth-child(even) {
        border-right-width: 0;
    }

    &.flex-col-full {
        width: 100%;
        border-right-width: 0;
    }

    &.flex-col3 {
        width: 33.33%;

        &:nth-child(even) {
            border-right-width: 1px;
        }

        &:nth-child(3n + 3) {
            border-right-width: 0;
        }

        @media (max-width: @screen-md) {
            width: 50%;
        }

        @media (max-width: @screen-sm) {
            width: 100%;
            border-right-width: 0;
        }
    }

    &.flex-col2-3 {
        width: 66.66%;
    }

    &.flex-col2 {
        width: 50%;

        @media (max-width: @screen-sm) {
            width: 100%;
            border-right-width: 0;
        }
    }
}

.custom-card-content-title{
    background-color: #e8faf6;
    color: #999;
    display: block;
    padding: 10px 20px;
    margin-bottom: 0;
    font-size: 16px;
    
    &.has-actions {
        display: flex;
        align-items: center;

        label {
            flex-grow: 1;
        }

        .fa {
            font-size: 24px;
        }
    }

    button {
        background: none;
    }

    label {
        margin-bottom: 0;
    }

    .flex-outline-hover {
        &:hover {
            border: 1px solid @button-color;
            cursor: pointer;
        }
    }

    .btn-close {
        color: @required-color;
        // margin-right: 20px;
    }

    // .btn-link {
    //     margin-left: 20px;
    // }
}

.flex-no-grow {
    flex-grow: 0;
}

.flex-grow {
    flex-grow: 1;
}

.no-padding {
    padding: 0;
}

.no-margin {
    margin: 0;
}

.custom-card {
    .panel-heading {
        padding: 12px 20px;
        background-color: @header-color;
        border: 0;
        overflow: auto;
        text-transform: uppercase;

        h3 {
            color: #fff;
            margin: 0;
            font-size: 20px;
        }
    }

    #history {
        .panel-heading {
            background-color: @sub-header-bg-color;
            color: @sub-header-color;
        }

        .panel-body {
            padding: 15px;
        }
    }
}

.sort-wrapper {
    padding: 20px 0 0;
    margin-bottom: 2rem;

    &.no-padding {
        padding: 0;
    }

    .sort-row {
        padding: 10px;
        margin-bottom: 1px;        

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.light-gray .sort-row {
        background-color: #f1f1f1;
    }

    & .sort-wrapper {
        margin-bottom: 0;
    }
}

.option-row {
    display: flex;
    align-items: center;
    // margin-bottom: 1rem;
}

// .sub-options {
//     padding: 0 25px;
// }

.has-actions {
    .form-group {
        margin: 0;
    }

    .help-block {
        display: none;
    }

    .btn-left {
        margin-right: 15px;
    }

    .btn-right {        
        margin-left: 15px;        
    }    

    .btn-close {
        color: @required-color;
        font-size: 20px;
        left: 10px;
        background: none;
    }

    .btn-sort {
        color: @text-link-color;        
    }    
}

*/

.elevation0 {
    box-shadow: unset;
}

.elevation1 {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.elevation2 {
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.elevation3 {
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
}

.navbar-fixed-top {
    &.practice {
        background-color: #4f868e;
    }

    &.local, &.develop {
        background-color: #803101;
    }
}

.devBanner {
    position: fixed;
    top: 16px;
    left: -6px; color: white;
    z-index: 2000; font-size: 14px;
    transform: rotate(-90deg);
    opacity: 0.7;
    font-weight: 800;
}

.multi-select-control {
    position: relative;
}

.multi-select-control-selection {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    display: none;
    box-shadow: 0px 3px 3px 2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
    font-size: 16px;
    z-index: 20;
    background: #fff;
    max-height: 500px;
    overflow: auto;

    &.active {
        display: block;
    }

    .button-with-icon {
        margin-bottom: 1px;
        border-left-width: 0;
        border-right-width: 0;
        &:last-child {
            border-top-width: 0;
        }
    }
}

.multi-select-control-display {
    display: flex;
    height: auto;
    min-height: 34px;
    flex-wrap: wrap;
    max-height: 200px;
    overflow: auto;
    padding-top: 10px;

    &.has-value {
        padding: 6px;
    }
}

.multi-select-control-selected {
    border-radius: 20px;
    padding: 4px;
    border: 1px solid #ccc;
    padding: 8px 50px 8px 15px;
    margin: 6px;
    position: relative;
    
    .close-btn {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        border: 0;
        background: none;
    }

    .fa-close {        
        color: @required-color;
    }
}

.disclaimer {
    font-size: 85%;
    font-style: italic;
    opacity: .7;
    color: #777
}

// Fullscreen Modal
.modal-fullscreen {
    > .modal-dialog {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;

        > .modal-content {
            height: auto;
            min-height: 100%;
            border-radius: 0;
        }
    }
}

//TextEditorControl
.session-notes-wrapper {

    .navbar-logo {
        @media (max-width: @screen-sm) {
            width: auto !important;
        }
    }

    .session-notes-header {
        line-height: 24px;
        font-weight: normal;
    }

    .session-notes-header {
        @media (max-width: @screen-sm) and (orientation: portrait) {
            td {
                display: block;
            }
        }
    }

    .DraftEditor-root {
        font-size: 16px;
        // border: 1px solid #ccc;
        // padding: 14px 10px;        
    }

    .react-rte {
        .form-control-placeholder {
            position: static !important;
        }
    }
    
    .has-error .DraftEditor-root {
        border-color: #a94442;
    }

    & .label-overflow .DraftEditor-root {
        padding: 30px 10px 10px;
    }
    
    .RichEditor-controls {
        margin-bottom: 5px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-size: 20px;
        text-align: right;
    
        .RichEditor-styleButton {
            color: #999;
            cursor: pointer;
            margin-right: 16px;
            padding: 2px 0;
            display: inline-block;

            &.RichEditor-activeButton {
                color: @primary-color;
            }
        }
    }

    .help-block {
        font-size: 80%;
        font-style: italic;
    }

    .field-radio, .field-checkbox {
        margin-bottom: 1rem !important;
    }

    .children-fields {
        .field-radio, .field-checkbox {
            margin-bottom: 0 !important;
        }

        .type-radio, .type-checkbox {
            .type-text {
                margin-top: 10px;
            }
        }
    }

    .field-radio + .children-fields, .field-checkbox + .children-fields {        
        .form-group {
            // margin-bottom: 0;
        }
    }

    .session-notes-footer {
        font-size: 12px;
        text-align: right;
    }
}

.circle-btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    position: relative;

    &:hover, &:focus, &:active {
        background-color: #fff;
    }

    .fa, .icon-signature {
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.warning-btn {
    background-color: @required-color;
    color: #fff;

    &:hover, &:focus, &:active {
        background-color: @required-color;
        color: #fff;
    }
}

.btn-blue {
    background-color: #337ab7;
    color: #fff;
    border: 0;

    &:hover, &:focus, &:active {
        background-color: #337ab7;
        color: #fff;
    }
}

.flex-row-min-height {
    @media (orientation: landscape) {
        min-height: 60px;
    }
    @media (orientation: portrait) {
        min-height: 80px;
    }
}

.flex-row-bordered {
    &.flex-row {
        border-bottom: 1px solid @sub-header-bg-color;

        &:last-child {
            border-bottom-width: 0;
        }
    }

    .flex-col {
        border-right: 1px solid @sub-header-bg-color;

        &:last-child {
            border-right-width: 0;
        }
    }
}

.flex-row-wrap {
    flex-wrap: wrap;
}

.flex-row {
    display: flex;
}

.flex-col {
    flex-grow: 1;
}

.flex-col-2 {
    width: 50%;

    @media (max-width: @screen-sm) {
        width: 100%;
    }
}

.flex-col-2-3 {
    width: 66.66%;

    @media (max-width: @screen-sm) {
        width: 100%;
    }
}

.flex-col-3 {
    width: 33.33%;

    @media (max-width: @screen-sm) {
        width: 100%;
    }
}

.flex-col-3-4 {
    width: 75%;

    @media (max-width: @screen-sm) {
        width: 100%;
    }
}

.flex-col-4 {
    width: 25%;

    @media (max-width: @screen-sm) {
        width: 100%;
    }
}

.flex-no-grow {
    flex-grow: 0;
}

.flex-striped {
    .flex-row:nth-child(even) {
        background-color: #e8faf6;
    }
}

a.sso-btn-link {
    border: 1px solid lightgray;
    display: inline-block;
    border-radius: 4px;
    padding: 12px;
    font-size: 16px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    width: 100%;
    margin-top:20px;
    margin-bottom:40px;
    div.one {
        display:inline-block;
        width: 110px;
    }
    div.two {
        display:inline-block;
        width: calc(100% - 110px);
        text-align: center;
        font-weight: 600;
    }
    img {
        max-width: 100px;
    }
}

.signInBtn { width: 155px }

.display-block {
    display: block;
}

.table-scroll {
    tbody {
        display:block;
        max-height:400px;
        overflow-y:scroll;
    }
    thead, tbody tr {
        display:table;
        width:100%;
        table-layout:fixed;
    }
}

.chip-container {
    background-color: #f2f3f5;
    //border-bottom: 1px solid #979ea8;   
    padding: 12px 20px 0px;

    .chip-item {
        background-color: #fff;
        &:last-child { 
            margin-right: 0;
        }
    }
}
.btn-sd-target { 
    margin-bottom: 10px; 
}

.sd-target-modal {
    .table-responsive {
        .table-cell {
            width:33%;
        }
    }
}
.container-fluid-margin {
    margin: 20px 15px;
    position: relative;
    // z-index: 1;
}

// freeze header on scroll
.client-navigation-padding {
    padding-top: 50px;

    &.client-admin {
        padding-top: 60px;
    }
}

.ds-page-header {
    // freeze header on scroll
    box-shadow: 5px 1px 18px #888888;
    position: fixed;
    top: 50px;
    width: 100%;
    z-index: 4;
    background: #fff;
    padding: 12px 15px;
    
    .client-admin & {
        width: calc(100% - 160px);

        &.no-side-nav {
            width: 100%;
        }

        @media (max-width: @screen-md) {
            width: 100%;
        }
    }

    @media (max-width: 375px) {
        padding-left: 0px;
        padding-right: 0px;
        > div.row {
            margin: 0 0px;
            > div {
                padding: 0 8px;
            }
        }
    }

    &.tabs {
        padding: 0 15px;

        .text-nowrap {
            line-height: 40px;
        }

        .nav-tabs {
            display: inline-block;
            vertical-align: bottom;
            border-bottom: 0;

            > li.active a {
                border-color: #c7c9c7;
                background-color: #c7c9c7;
            }
        }
    }
}

.visibility-hidden {
    z-index: -1;
    visibility: hidden;
}

// Source: https://code-boxx.com/freeze-rows-columns-html-tables/
.freeze-rows-columns {
    width: 100%;
    max-height: 630px;
    overflow: auto;
    
    // table {
    //     border-collapse: separate;
    // }    
}

/* (B) STICKY HEADERS */
.freeze-rows-columns tbody th, .freeze-rows-columns thead, .freeze-rows-columns .sticky {
    position: sticky;
    top: 0;
    z-index: 2;
    // outline: 1px solid #ccc;
}
.freeze-rows-columns th[scope=row], .freeze-rows-columns .sticky {
    // background: #bce6db;
    left: 0;
    z-index: 1;
}

/* (C) COSMETICS - NOT IMPORTANT */
.freeze-rows-columns th, .freeze-rows-columns td {
    min-width: 200px;
    border-width: 1px !important;
    border-color: #ccc !important;
    // box-shadow: 0 0 0 1px #ccc !important;
}

.freeze-rows-columns thead th {
    // box-shadow: 0 0 0 1px #ccc !important;
}

// .freeze-rows-columns th {
//     border-color: #fff !important;
// }

.freeze-rows-columns th { background: #bce6db; }
.freeze-rows-columns td { background: #fff; }

.tab-wrapper {
    display: flex;
    border-bottom: 1px solid #dee2e6;

    .tab-item {
        border: 1px solid #fff;
        border-bottom: 1px solid #dee2e6;
        margin-bottom: -1px;
        padding: 10px 20px;
        cursor: pointer;
        opacity: .7;

        &.active {
            border: 1px solid #dee2e6;
            border-bottom: 1px solid #fff;
            border-top-left-radius: .25rem;
            border-top-right-radius: .25rem;
            background-color: #bce6db;
            opacity: 1;
        }

        &:hover {
            border: 1px solid #dee2e6;              
            border-top-left-radius: .25rem;
            border-top-right-radius: .25rem;
            
            &.active {
                border-bottom: 1px solid #fff;                
            }
        }
    }
}
// significant event
.significant-modal {
    .form-section {
        display: flex;
        justify-content: space-between;

        .form-group {
            width: 48%;
            margin-bottom: 1rem;
        }
    }
    .select-all-modal-header {
        background-color: #bce6db;
        color: #5e5e5e;
        font-weight: bold;
        display:flex;
        align-items: flex-start;
        padding: 10px 20px;
        text-transform: uppercase;
        font-size: 14px;

        fieldset {
            min-width: 0;
            padding: 0;
            margin: 0;
            border: 0;
            label {
                max-width: initial;
            }
        }

        .help {
            font-size: 11px;
            text-transform: initial;
        }
    }
}

.tab-content {
    padding: 20px 20px 0 20px;
    border: 1px solid #dee2e6;
    border-top: 0;
}

.button-with-icon {
    display: flex;
    margin-bottom: 4px;

    span {
        flex-grow: 1;
        display: block;
    }

    .fa {
        flex-grow: 0;
        font-size: 1.5em;
        opacity: .5
    }

    &.active {
        background-color: @text-link-color !important;
        border-color: @text-link-color !important;
        color: #fff;
        
        .fa, .icon {
            opacity: 1;
            color: #fff;
            filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(0%) hue-rotate(161deg) brightness(102%) contrast(101%);
        }

        & .text-muted {
            color: #fff;
        }
    }
}

.competency-checklist {
    .header-buttons {
        button {
            position: relative;
            .fa {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                &.fa-plus {
                    margin-top: 0;
                    font-size: 24px;
                }
            }

        }
    }
}

.fa-smile:before {
    content: '\f118';
}

.modal-dialog {
    // Overwriting header to allow flexible close button location (left/right) and additional buttons

    .modal-header {
        display: flex;
        align-items: center;
    }

    .modal-title {
        flex-grow: 1;
    }

    .close {
        flex-grow: 0;
        padding: 0 10px;
    }

    .buttons-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .btn-header {
        width: 40px;
        height: 40px;
        border: 2px solid #fff;
        border-radius: 50%;
        position: relative;
        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
        background-color: #fff;
        color: #6cace4;
        vertical-align: middle;
        margin-left: 10px;
        
        & i {
            font-size: 26px;
        }

        &.disabled {
            box-shadow: initial;
            opacity: 0.7;
        }
    }

    .panel-heading {
        .btn-header {
            border: 2px solid #007DBA;
            box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12);
            background-color: #007DBA;
            color: #fff;
        }
    }
}

.modal-backdrop {
    z-index: 1050;
}

.main-card {
    box-shadow: 0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12);
    border-radius: 0.5rem;
    border-width: 1px;
    background-color: #fff;
    margin-top: 30px;

    &:first-child {
        margin-top: 20px;
    }
}

.filter-button-section {
    position: absolute;
    text-transform: initial;
}

.badge {
    &.highlight-required {
        color: #fff;
        background-color: @required-color;
    }
}

.disabled-layer {
    pointer-events: none;
    opacity: .5;
}