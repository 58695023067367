.video-warning {
    color: red;
    font-weight: bold;
}

.ackCountDown {
    span {
        padding-left: 6px;
        color: red;
        min-width: 1.4em;
        display: inline-block;
    }
    @media (min-width: @screen-sm) {
        text-align: right;

    }
}

div.test-question {
    position: relative;
    padding-left: 3em;
    min-height: 2em;
    button.test {
        position: absolute;
        left: 0;
        top: -4px;
    }
    span {
        line-height: 1.6em;
    }
    margin-bottom: 6px;
}

.test-seconds {
    display: inline-block;
    min-width: .7em;
}

button.test, button.test:active {
    -webkit-box-shadow:none;
    box-shadow:none;
    border:none;
    background-color:white;
}

i.warning {
    color: yellow;
    background-color:#337ab7;
    border-radius: 4px;
    padding: 4px 8px;
}

.question-panel {
    min-height: 14em;
}
.test-button {
    > .btn {
        width: 4.75em;
        margin-right: 0;
    }
    > hr {
        margin: 15px -15px;
    }
    .btn.test-done {
        width: 7em;
    }
}
.test-button > .btn:last-child {
    margin-right: 0;
}
.test-question-header {
    margin-bottom: 12px;
    padding: 0 4px;
}
.test-question-confirm {
    margin-top: 16px;
    margin-bottom: 8px;
    button {
        min-width: 100px;
    }
}
div.test-block {
    h5 {
        margin: 0 0 10px 0;
    }
}

button.start-test {
    min-width: 7em;
}

@media ( max-width: 500px ) {
    .test-button > .btn {
        margin-right: 0px;
    }
}

.video-warning {
    color: red;
    font-weight: bold;
}

.restart-video {
    margin-top:20px;
}

.status {
    margin-top: -10px;
    margin-bottom: 20px;
    font-style: italic;
}

div.account-modal {
    top: 100px;//this is return from fullscreen on galaxy
}

.pc-player > div > {
    .jw-preview {
        background-size: cover;
    }
}

.white-space-break {
    margin-top: 15px;
}
.large-white-space-break {
    margin-top: 25px;
}
h5.material-list {
    margin-bottom: 6px;
}
h5.material-list-group {
    margin: 5px 0;
}

.h-highlight-container {
    padding: 4px 15px 0 15px;
    margin:  -4px -15px 0 -15px;
}
.h-highlight-container-ready {

    -webkit-animation: fadeout 10s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeout 10s; /* Firefox < 16 */
    -ms-animation: fadeout 10s; /* Internet Explorer */
    -o-animation: fadeout 10s; /* Opera < 12.1 */
    animation: fadeout 10s;
}

@keyframes fadeout {
    from { background-color: lightyellow; }
    to   { background-color: transparent; }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
    from { background-color: lightyellow; }
    to   { background-color: transparent; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
    from { background-color: lightyellow; }
    to   { background-color: transparent; }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
    from { background-color: lightyellow; }
    to   { background-color: transparent; }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
    from { background-color: lightyellow; }
    to   { background-color: transparent; }
}
