div.home-hero {
    letter-spacing: .2em;
    position: absolute;
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
    padding: 0px;
    color: white;
    z-index: 2;
    max-width: 1180px;
    margin: 0 auto;
}
div.home-hero.clearnav {
    top: 50px;
    bottom: -50px;
}
.home-hero-overlay {
    position:absolute;
    background-color:rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    height: calc(100% - 5px);
    width: 100%;
    z-index: 1;
}
.home-hero-overlay {
}

h1.home-hero {
    font-size: 64px;
    line-height: 1em;
    margin-top: calc(-120px + 13%);
}

.home-hero-grayscale {
    /*
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    filter: grayscale(100%);
    */
    -webkit-filter: blur(1px);
    -moz-filter: blur(1px);
    -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(1px);
}


.home-hero-text {
    line-height: 1.4em;
    color: white;
    font-size: 20px;
    font-weight: 600;
    padding: 0 15px;
}

h4.home-hero-text.lower {
    max-width: 600px;
}


.home-hero-bg-play-pause {
    position: absolute;;
    right: 6px;
    top: 8px;
    background-color: rgba(0,0,0,0.2);
    z-index: 3;
    padding: 3px;
    border-radius: 6px;
    color: white;
    > i {
        font-size: 20px;
        opacity: 1;
        padding-left: 8px;
        padding-right: 8px;
    }
}
.home-hero-bg-play-pause.clearnav {
    background-color: rgba(0,0,0,0);
    right: 8px;
    bottom: 14px;
    top: auto;

}
.home-hero-button-text {
    font-size: 16px;
    text-align: left;
    font-weight: 600;
}
h3.home-hero-button-header {
    margin: 0;
    text-align: center;
}

a.home-hero-button {
    border: 1px solid white;
    position:relative;
    padding: 0px;
    display: inline-block;
    vertical-align: top;
    color: white;
    width: 220px;
    margin: 20px 2%;
    text-decoration: none;
    border-radius: 8px;
    font-size: 24px;
    display: inline-block;
    min-height: 260px;
}
a.home-hero-button:active {
    color: lightgray;
    border-color: lightgray;
}
div.home-hero-button-header {
    border-bottom: 1px solid white;
    padding: 8px 20px;
}
div.home-hero-button-content {
    padding: 20px 26px;
}
a.home-hero-button:visited {
    color: white;
}



.home-hero-learn-more {
    position: absolute;
    bottom: 4px;
    > i {
        font-size: 18px;
        padding-left: 4px;
    }
}

h4.home-hero-text {
    font-weight: 700;
    margin: 2% auto 2% auto;
}
@media(min-width: 1091px) {
    /*
    .row.equal > .home-hero-show-1090 {
        display:none;
    }
    */

}
@media(max-width: 1091px) {
    h1.home-hero {
        margin-top: calc(-14px + 4%);
    }
    a.home-hero-button {
        margin-left: 1%;// calc(-1px + 1%);
        margin-right: 1%;// calc(-1px + 1%);
    }
    h4.home-hero-text {
        margin: calc(-23px + 4.6%) auto;
    }
    a.home-hero-button {
        min-height: 230px;
        width: 191px;
        margin: 20px calc(-10px + 3%);
    }
    div.home-hero-button-content {
        padding: 20px;
    }
    .home-hero-hide-1090 {
        display:none;
    }
    video#bgvid.clearnav {
        width: 106%;
    }
}
@media(max-width: 1000px) {
    a.home-hero-button {
        margin: 6px 2%;
    }
    a.home-hero-button {
        width: 190px;
        margin-left: calc(2px + 1.1%);
        margin-right: calc(2px + 1.1%);
    }
    video#bgvid.clearnav {
        width: 110%;
    }
    h1.home-hero {
        margin-top: calc(10px + 1.9%);
    }

}
@media(max-width: 875px) {
    h1.home-hero {
        margin-top: calc(10px + 1.9%);
    }
    .home-hero-button.demo {
        display:none;
    }
    a.home-hero-button {
        margin: 6px 2%;
    }
    a.home-hero-button {
        width: 200px;
        margin-left: calc(20px + 1%);
        margin-right: calc(20px + 1%);
    }
    video#bgvid.clearnav {
        width: 114%;
    }

}

@media(max-width: 850px) {
    h1.home-hero {
        margin-top: calc(10px + 1.9%);
    }
    .home-hero-button-text {
        font-size: 14px;
    }
    a.home-hero-button {
        width: 190px;
        min-height: 190px;
    }
    div.home-hero-button-content {
        padding: 16px 20px;
    }
    h4.home-hero-text {
        margin: calc(-15px + 4.6%) auto;
    }
    video#bgvid.clearnav {
        width: 114%;
    }

}

@media(max-width: 800px) {
    h1.home-hero {
        margin-top: calc(-1px + 1.9%);
    }
    h4.home-hero-text {
        margin: calc(-23px + 6%) auto;
    }
    p.home-hero-button-text {
        margin-bottom: 7px;
    }
    a.home-hero-button {
        min-height: 180px;
    }
}
@media(max-width: 768px) {//video changes at 769

    h1.home-hero {
        margin-top: calc(-77px + 15%);
    }

    h4.home-hero-text.upper {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
    }
    a.home-hero-button {
        width: 188px;
        margin-left: 16px;
        margin-right: 16px;
    }
    h4.home-hero-text {
        margin: calc(-23px + 6%) auto;
    }
    video#bgvid.clearnav {
        width: 135%;
    }
    .home-hero-bg-play-pause.clearnav {
        background-color: rgba(0, 0, 0, 0);
        right: 0px;
        bottom: auto;
        top: 54px;
    }
}
@media(max-width: 700px) {
    h1.home-hero {
        margin-top: calc(-77px + 15%);
    }
    a.home-hero-button {
        width: 180px;
        min-height: 180px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    h4.home-hero-text {
        margin: calc(-23px + 5.2%) auto;
    }
    video#bgvid.clearnav {
        width: 135%;
    }
}
@media(max-width: 650px) {
    h1.home-hero {
        margin-top: calc(-57px + 15%);
    }
    div.home-hero-button-content {
        display: none;
    }
    a.home-hero-button {
        width: 150px;
        min-height: auto;
        margin-top: 12px;
        margin-bottom: 11px;
    }
    div.home-hero-button-header {
        border: none;
    }
    .home-hero-bg-play-pause {
        top: calc(100% - 50px);
        right: 4px;
    }
    .home-hero-bg-play-pause.clearnav {
        top: 100%;
    }
    h4.home-hero-text {
        margin: calc(-15px + 6.5%) auto;
    }
    h4.home-hero-text.lower {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }
    video#bgvid.clearnav {
        width: 136%;
    }
    .home-hero-bg-play-pause.clearnav {
        background-color: rgba(0, 0, 0, 0);
        right: 8px;
        bottom: 14px;
        top: auto;
    }
}
@media(max-width: 550px) {
    h1.home-hero {
        font-size: 34px;
        margin-top: calc(-57px + 15%);
    }
    div.home-hero-button-header {
        padding: 8px 0px;
    }
    a.home-hero-button {
        width: 125px
    }
    h4.home-hero-text {
        margin: 3.0% auto;
    }
    video#bgvid.clearnav {
        width: 136%;
    }
}
@media(max-width: 500px) {
    a.home-hero-button {
        width: 115px
    }
    video#bgvid.clearnav {
        width: 140%;
    }
}
@media(max-width: 450px) {

    h1.home-hero {
        font-size: 34px;
    }

    h1.home-hero {
        margin-top: 2%;
    }
    h3.home-hero-button-header {
        font-size: 20px;
    }
    a.home-hero-button {
        width: 100px
    }
    h1.home-hero {
        margin-top: calc(-12px + 8%);
    }
    a.home-hero-button {
        margin-left: calc(10px + 1%);
        margin-right: calc(10px + 1%);
    }
    .home-hero-text.hidden-400 {
        display: none;
    }
    video#bgvid.clearnav {
        width: 137%;
    }
}
@media(max-width: 400px) {
    a.home-hero-button {
        width: 80px;
        margin-top: 8px;
        margin-bottom: 8px;
    }
    h4.home-hero-text {
        margin: 3.0% auto;
    }
    .home-hero-text.hidden-400 {
        display: none;
    }
    h1.home-hero {
        font-size: 34px;
        margin-top: calc(-70px + 24%);
    }
    video#bgvid.clearnav {
        width: 142%;
    }
    .home-hero-bg-play-pause.clearnav {
        right: 0px;
        bottom: 6px;
    }
}
@media(max-width: 375px) {
    h4.home-hero-text {
        margin: 2.0% auto;
    }
    h1.home-hero {
        margin-top: calc(-65px + 24%);
    }
    h1.home-hero.clearnav {
        margin-top: calc(-62px + 24%);
    }
    video#bgvid.clearnav {
        width: 148%;
    }
}

