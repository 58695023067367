.sort-view-sort {
    display:inline-block;
    width: calc(100% - 60px);
    margin-right:10px;
}
.sort-view-view {
    display:inline-block;
    width: 45px;
    margin-left: 5px;
}

.searchclear {
    display: inline-block;
    position: absolute;
    top: 26px;
    right: 20px;
    font-size:24px;
    margin: auto;
    cursor: pointer;
    color: lightgray;
    i {
        background: white;
    }
}

.view-button {
    padding-top: 5px;
    > i {
        font-size: 19px;
        line-height: 19px;
        transform: translateY(1px);
    }
}
