.pc-checkbox input[type="checkbox"] {
    opacity: 0;
    width: 0;
}

.pc-checkbox label {
    position: relative;
    display: inline-block;
    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 32px;
    height:1.375em;
    /*font-weight: normal;*/
}

.pc-checkbox label::before,
.pc-checkbox label::after {
    position: absolute;
    content: "";
    /*Needed for the line-height to take effect*/
    display: inline-block;
}

/*Outer box of the fake checkbox*/
.pc-checkbox label::before {
    height: 1.2em;
    width: 1.2em;
    border: 2px solid;
    left: 0px;
    /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
     *to vertically center it.
     */
    border-radius: .2em;
    top: 2px;
    cursor:pointer;
}

/*Checkmark of the fake checkbox*/
.pc-checkbox label::after {
    height: 10px;
    width: 22px;
    border-left: 4px solid;
    border-bottom: 4px solid;
    transform: rotate(-45deg);
    left: 2px;
    top: 1px;
    border-radius: .3em;
    cursor:pointer;
}

/*Hide the checkmark by default*/
.pc-checkbox input[type="checkbox"] + label::after {
    content: none;
}

/*Unhide on the checked state*/
.pc-checkbox input[type="checkbox"]:checked + label::after {
    content: "";
}

.pc-checkbox input[type="checkbox"]:indeterminate + label::after {
    content: none;
}

/*Adding focus styles on the outer-box of the fake checkbox*/
.pc-checkbox input[type="checkbox"]:focus + label::before {
    outline: rgb(59, 153, 252) auto 2px;
}
.pc-checkbox-no-focus input[type="checkbox"]:focus + label::before {
    outline: none;
}

.pc-checkbox > input:indeterminate + label {
    border-bottom: 4px solid black;
    -webkit-transform: scaleX(.5) translateY(-6px) translateX(-7px);
    -moz-transform: scaleX(.5) translateY(-6px) translateX(-7px);
    -ms-transform: scaleX(.5) translateY(-6px) translateX(-7px);
    -o-transform: scaleX(.5) translateY(-6px) translateX(-7px);
    transform: scaleX(.5) translateY(-6px) translateX(-7px)
}
.pc-checkbox > input:indeterminate + label::before {
    -webkit-transform: scaleX(2) translateY(6px) translateX(2px);
    -moz-transform: scaleX(2) translateY(6px) translateX(2px);
    -ms-transform: scaleX(2) translateY(6px) translateX(2px);
    -o-transform: scaleX(2) translateY(6px) translateX(2px);
    transform: scaleX(2) translateY(6px) translateX(2px);
}

.pc-checkbox.lightweight label {
    font-weight: 400;
}
.pc-checkbox.lightweight label::before {
    border: 1px solid;
}
.pc-checkbox.lightweight label::after {
    border-left: 2px solid;
    border-bottom: 2px solid;
    border-radius: .2em;
}
fieldset.pc-checkbox:disabled > input, fieldset.pc-checkbox:disabled > label {
    color: #aaa;
    opacity: 1;
}
fieldset.pc-checkbox.disabled {
    color: #aaa;
    opacity: 1;
}
