.shopping-cart > a {
    text-decoration: none;
}
.shopping-cart {
    display:inline-block;
    font-size: 2.5em;
    padding-left: 8px;


}
.shopping-cart > a > i {
    color: white;
    font-size:36px;
    @media (max-width: @screen-xs) {
        font-size: 28px;
    }
}
.shopping-cart > a > sup {
    font-size: 20px;
    top: -.4em;
    background-color: #ef485e;
    color:white;
    border-radius: 8px;
    padding: 0 5px;
    left: -10px;
}
.shopping-cart-collapsed {
    display: inline-block;
    float:right;
    margin-right: 15px;
    @media (max-width: @screen-xs) {
        font-size: 2.5em;
        margin-right: 0;

    }
}
@media (min-width: @screen-md) {
    .shopping-cart-collapsed {
        display:none;
    }
    .shopping-cart-not-collapsed {
        display: inline-block;
    }

}
/*
@media (max-width: @screen-md) {
    .shopping-cart-not-collapsed {
        display: none;
    }
    .shopping-cart-collapsed {
        display:inline-block;
        float:right;
    }
}*/
.cart-contents {
    margin-top: 15px;
}

.cart-trash {
    color: gray;
    position: relative;
    top: 7px;
    font-size:1.6em;
    cursor: pointer;
}
.cart-trash-div {
    cursor:pointer;
}
